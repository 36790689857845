<template>
  <div>
    <Breadcrumbs
      v-if="breadcrumbs && pageTypeString && pageTypeString !== 'ProductPage'"
      :breadcrumbs="breadcrumbs"
      :title="pageData.NameInBreadcrumbs || pageData.Name || ''"
      :class="{ 'absolute text-white z-10': heroFirstBlock }"
    />
    <div
      v-show="isEditOrPreviewMode() && editPageIsLoading"
      class="w-full md:text-center mt-[300px]"
    >
      <div
        class="loader ease-linear rounded-full border-4 border-t-4 border-brand-dark h-32 w-32 inline-block mb-24 max-w-1/2 mx-auto"
      />
      <div class="mt-12">
        Pages with multiple blocks can take up to 60 seconds to preview (Opti graph limitation)
      </div>
    </div>
    <component
      :is="pageComponent"
      v-if="pageComponent"
      :page="pageData"
      :is-mobile="isMobile"
      :is-tablet="isTablet"
      :is-edit-mode="isEditOrPreviewMode()"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { usePageContentStore } from '~/store/pageContent';
import { useGlobalContentStore } from '~/store/globalContent';
import { storeToRefs } from 'pinia';
import Breadcrumbs from '~/components/body/Breadcrumbs.vue';
import { useVoyadoStore } from '~/store/voyado';
import { useUserStore } from '~/store/user';
import { useUiStore } from '~/store/ui';
import { type IRedirectsResult } from '~/models/api-types';
import * as Sentry from '@sentry/vue';
import { useFavouritesStore } from '~/store/favourites';
import { ClassificationTypeNames } from 'typescript';

const uiStore = useUiStore();
const { isMobile, isTablet, forceHideAgeGate } = storeToRefs(uiStore);
const voyadoStore = useVoyadoStore();
const userStore = useUserStore();
const globalContentStore = useGlobalContentStore();
const config = useGlobalContentStore().config;
const pageStore = usePageContentStore();
const { generateGQLQueryVars } = useGenerateGQLQueryVars();
const breadcrumbs = ref();
const host = globalContentStore.host;
const { currentMarket } = config;
const runTimeConfig = useRuntimeConfig();
const foundPreviewPage = ref();
const editPageIsLoading = ref(false);

const route = useRoute();
const fetchedPageData = ref();
const { pageTypeString } = storeToRefs(pageStore);
const pageType = ref(useGlobalContentStore().pageType);

// Redirects based on cookie for current market and going to empty path
const ageGateCookie = useCookie('age_gate_market');
if (
  config?.theme === 'thunder' && ageGateCookie.value &&
  (route?.path === '/' || !route?.path)
) {
  navigateTo(ageGateCookie.value);
}

const isEditOrPreviewMode = () => {
  const route = useRoute();
  return route.query.epieditmode === 'false' || route.query.epieditmode === 'true';
};

const previewToken = route.query.preview_token?.toString();
const contentLanguage = config?.language || route.query.language;
const startPageId = currentMarket?.startPageId;
let variables = generateGQLQueryVars(previewToken, route.path, contentLanguage, startPageId);

// use preview token
if (previewToken && isEditOrPreviewMode()) {
  useGqlHeaders({ 'Authorization': `Bearer ${previewToken}` });
}

let gqlUrl = runTimeConfig.public.GQL_HOST;

if (pageType.value !== 'ArticleListingPage') {
  gqlUrl += '&stored=true&cache_uniq=true';
}

useGqlHost(gqlUrl);

// Loading page
const { data, refresh } = await useAsyncGql('Start', variables);

useGqlHost(runTimeConfig.public.GQL_HOST!);

if (
  data.value &&
  data.value.Content?.items &&
  data.value.Content?.items.length > 0 &&
  data.value?.Content?.items[0]?.__typename &&
  data.value?.Content?.items[0]?.__typename != 'ProductContainerPage' &&
  config.isHostnameConfigured
) {
  fetchedPageData.value = data.value;
  if (data.value?.Content?.items) {
    pageTypeString.value = data.value?.Content?.items[0]?.__typename;
  }

  const fetchedBreadcrumbs = data.value?.Content?.items[0]?.Breadcrumbs?.Content?.items;

  if (fetchedBreadcrumbs) {
    // Remove startpage
    const startpageIndex = fetchedBreadcrumbs.findIndex((obj: { RelativePath: string; }) => obj?.RelativePath === '/');
    if (startpageIndex > -1) fetchedBreadcrumbs.splice(startpageIndex, 1);

    const startPageThunderIndex = fetchedBreadcrumbs.findIndex((obj: { RelativePath: string; }) => obj?.RelativePath === '/de');
    if (startPageThunderIndex > -1) fetchedBreadcrumbs.splice(startPageThunderIndex, 1);

    // Add trailing slash to RelativePath if not present
    fetchedBreadcrumbs.forEach((obj: { RelativePath: string; }) => {
      if (obj?.RelativePath && !obj.RelativePath.endsWith('/')) {
        obj.RelativePath += '/';
      }
    });
  }
  breadcrumbs.value = fetchedBreadcrumbs;
  pageStore.setPageContent(fetchedPageData.value);
} else {
  const baseUrl = process.client ? window.location.origin : `https://${useRequestHeaders()['x-forwarded-host']}`;
  const contentUrl = new URL(route.fullPath, baseUrl);
  contentUrl.protocol = 'https:';
  let hasRedirected = false;

  const redirectResult = await $fetch(`${process.client ? runTimeConfig.public.apiUrl : runTimeConfig.apiUrl}website/redirect?contentUrl=${contentUrl.href}`) as IRedirectsResult;
  if (redirectResult != null && redirectResult.shouldRedirect) {
    hasRedirected = true;
    const newUrl = new URL(redirectResult.newUrl);
    await navigateTo(newUrl.href, {
      redirectCode: redirectResult.permanent ? 301 : 302,
      external: true,
    });
  }

  useGqlError((err) => {
    let errMessage = '';
    for (const gqlError of err.gqlErrors) {
      if (Sentry && Sentry.captureException) {
        errMessage = gqlError.message;
        Sentry.captureException(errMessage);
      }
    }
    if (err.statusCode) {
      throw createError({
        statusCode: 500,
        message: errMessage,
      });
    }
  });

  let interval: any;
  let count = 0;

  // If is edit or preview, wait for index before display 404
  if (isEditOrPreviewMode()) {
    editPageIsLoading.value = true;

    interval = setInterval(async() => {
      count++;
      if (count < 15 && !foundPreviewPage.value) {
        refresh();

        if (typeof window !== 'undefined' && data.value?.Content?.items && data.value?.Content?.items.length > 0) {
          editPageIsLoading.value = false;
          foundPreviewPage.value = true;
          clearInterval(interval);
        }
      } else if (!foundPreviewPage.value) {
        editPageIsLoading.value = false;
        clearInterval(interval);
        throw showError({
          statusCode: 404,
          statusMessage: 'Page Not Found',
          fatal: true,
        });
      }
    }, 5000);
  } else if (!hasRedirected) {
    if (!route.path.startsWith('/api/') && route.path) {
      throw createError({
        statusCode: 404,
        statusMessage: 'Page Not Found',
      });
    }
  }
}

// Page data
const pageData = computed(() => {
  if (fetchedPageData.value?.Content?.items && fetchedPageData.value?.Content?.items.length) {
    return fetchedPageData.value?.Content?.items[0];
  }
  return null;
});

// Resolve page component
const pageComponent = computed(() => {
  if (fetchedPageData.value?.Content?.items && fetchedPageData.value?.Content?.items.length) {
    let componentName = fetchedPageData.value?.Content?.items[0]?.__typename;
    const resolved = resolveComponent(componentName);
    if (typeof resolved === 'object') {
      return resolved;
    }
  }
  return '';
});

const heroFirstBlock = computed(() => {
  if (pageData.value.MainContent && pageData.value.MainContent[0]) {
    return pageData.value.MainContent[0].ContentLink.Expanded.__typename === 'HeroBlock';
  }
  return false;
});

const eclubId = ref(route.query.eclub);
if (process.client && eclubId.value && (window as any).va) {
  await voyadoStore.setContactIdFromEclubQueryParam(route.query.eclub as string);
}

onMounted(async()=> {
  if (config.currentMarket?.enableGlobalAgeVerification && config.isLoggedIn) {
    await userStore.checkIsVerified();
  };

  if (config.isLoggedIn) {
    Sentry.setTag('signedIn', true);
  }

  setTimeout(() => {
    if (config.isLoggedIn) {
      const favouritesStore = useFavouritesStore();
      favouritesStore.fetchFavourites();
    }    
  }, 1000);

  trackPageView();
  OptanonWrapper();
});

const OptanonWrapper = () => {
  if (process.client) {
    const win = window as any;
    win.oneTrustPerformanceCookieKey = 'C0003';

    let interval: any;
    let count = 0;
    // Wait for script to load
    interval = setInterval(() => {
      count++;
      if (count < 8) {
        if (win.OneTrust) {
          clearInterval(interval);
          win.OneTrust?.OnConsentChanged(function(event: any) {
            if (event.detail?.indexOf(win.oneTrustPerformanceCookieKey) !== -1) {
              enableAiCooies();
            } else {
              disableAndClearAiCookies();
            }
          });
        }
      }
    }, 500);
  }
};

const disableAndClearAiCookies = () => {
  const win = window as any;

  if (typeof win.appInsights === 'object' && typeof win.appInsights.config === 'object') {
    win.appInsights.config.isCookieUseDisabled = true;
  }
  document.cookie = 'ai_user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = 'ai_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};

const enableAiCooies = () => {
  const win = window as any;

  if (typeof win.appInsights === 'object' && typeof win.appInsights.config === 'object') {
    win.appInsights.config.isCookieUseDisabled = false;
    var cookieMgr = win.appInsights.getCookieMgr();
    if (cookieMgr) {
      cookieMgr.setEnabled(true);
      var sessionId = win.appInsights.context.getSessionId();
      if (sessionId) {
        cookieMgr.set('ai_session', sessionId);
        Sentry.setTag('ai_session', sessionId);
      }
      var userId = null;
      if (win.appInsights.context.user && win.appInsights.context.user.id) {
        userId = win.appInsights.context.user.id;
      }
      if (userId) {
        cookieMgr.set('ai_user', userId);
        Sentry.setTag('ai_user', userId);
      }
    }
  }
};

const footerConfig = computed(() => {
  return config.footer;
});

const trackPageView = () => {
  if (process.client) {
    const win = window as any;
    if (typeof win.appInsights !== 'undefined') {
      win.appInsights.trackPageView();
    }
  }

  if (data.value?.Content?.items[0]?.__typename !== 'ProductPage') {
    window.dataLayer?.push({
      event: 'pageView',
      pageType: 'PageView',
      pageUrl: route.fullPath,
      routeName: 'all',
      pageTitle: data.value?.Content?.items && data.value?.Content?.items[0] ? data.value?.Content?.items[0].Name : '',
    });
  }
};

// Set fetched page data if found previewPage
watch(
  () => foundPreviewPage.value,
  () => {
    fetchedPageData.value = data.value;
  }
);

watch(
  () => pageTypeString.value,
  () => {
    pageType.value = pageTypeString.value;
  }
);

const showAgeGate = computed(() => {
  return (
    !ageGateCookie.value &&
    config.markets &&
    config.markets.length > 0 &&
    !forceHideAgeGate.value
  );
});

const siteName = computed(() => {
  if (isDKMarket.value) {
    return 'NIQO CO';
  }
  if (config.theme !== 'thunder') {
    return 'ZYN';
  }
  return 'Thunder';
});

const isDKMarket = computed(() => {
  return config.currentMarket?.countryCode === 'DK';
});

const faviconPath = computed(() => {
  if (isDKMarket.value) {
    return 'favicons-niqo';
  }
  if (config.theme !== 'thunder') {
    return 'favicons-zyn';
  }
  return 'favicons-thunder';
});

type MetaBlock = {
  MetaTitle: string,
  MetaDescription: string;
  OpenGraphTitle: string;
  OpenGraphDescription: string;
  OpenGraphImage: {
    Url: string
  };
  NoIndex: boolean;
  CanonicalLink: string;
}

// Meta
const meta: Ref<MetaBlock> = ref(pageData.value && pageData.value.Meta ? pageData.value.Meta : null);
const title = ref((meta.value && meta.value.MetaTitle) ?? '');
const metaDescription = ref((meta.value && meta?.value.MetaDescription) ?? '');
const openGraphTitle = ref((meta.value && meta?.value.OpenGraphTitle) ?? '');
const openGraphDescription = ref((meta.value && meta?.value.OpenGraphDescription) ?? '');
const openGraphImage = ref((meta.value && meta?.value.OpenGraphImage) ?? '');
const noIndex = ref((meta.value && meta?.value.NoIndex) ?? false);
const canonicalLink = ref((meta.value && meta?.value.CanonicalLink) ?? '');

const hrefLangs: {
  rel: string;
  href: string | undefined;
  hreflang: string;
}[] = [];

// GTM
const gtmId = ref('');
if (
  config?.theme?.toLocaleLowerCase() === 'thunder' &&
  (runTimeConfig.public.environmentName.toLocaleLowerCase() === 'production' ||
  runTimeConfig.public.environmentName.toLocaleLowerCase() === 'preproduction')
) {
  gtmId.value = 'GTM-5N6MD98';
} else if (
  runTimeConfig.public.GTM_ID &&
  config?.theme?.toLocaleLowerCase() === 'niqo'
) {
  gtmId.value = runTimeConfig.public.GTM_ID;
};

const dataDomainScript = ref('');
if (config.currentMarket?.countryCode === 'SE') {
  if (runTimeConfig.public.environmentName.toLocaleLowerCase() === 'production' ||
        runTimeConfig.public.environmentName.toLocaleLowerCase() === 'preproduction')
  {
    dataDomainScript.value = '0194689b-da9e-75ce-b539-a6402fa39f49';
  } else {
    dataDomainScript.value = '0194689b-da9e-75ce-b539-a6402fa39f49-test';
  }
}
else if (config.theme !== 'thunder') {
  if (runTimeConfig.public.environmentName.toLocaleLowerCase() === 'production' ||
        runTimeConfig.public.environmentName.toLocaleLowerCase() === 'preproduction')
  {
    dataDomainScript.value = '09f67e2f-3a89-4a30-8314-7e0667a92c2c';
  } else {
    dataDomainScript.value = '09f67e2f-3a89-4a30-8314-7e0667a92c2c-test';
  }
} else {
  if (runTimeConfig.public.environmentName.toLocaleLowerCase() === 'production')
  {
    dataDomainScript.value = '717514ea-d76b-4d91-890d-12d0a15896d4';
  } else {
    dataDomainScript.value = '717514ea-d76b-4d91-890d-12d0a15896d4-test';
  }
}

if (pageData.value) {

  const script = [
    {
      src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      type: 'text/javascript',
      'data-document-language': 'true',
      'data-domain-script': dataDomainScript.value,
    },
    {
      innerHTML: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId.value}');
      `,
    },
  ];

  if (isEditOrPreviewMode()) {
    script.push({
      hid: 'epi',
      src: '/episerver/cms/latest/clientresources/communicationinjector.js',
      defer: true,
      callback: () => {
        if (
          typeof window !== 'undefined' && window.epi && isEditOrPreviewMode()
        ) {
          // Update page data in edit mode when content saved is triggered
          window.epi.subscribe('contentSaved', async(message: any) => {
            refresh();
            fetchedPageData.value = data.value;
          });
        }
      },
    });
  }

  const googleSiteVerification = computed(() : string | undefined => {
    if (config.theme === 'thunder') {
      return runTimeConfig.public.googleSiteVerificationThunder?.toString();
    }
    return runTimeConfig.public.googleSiteVerification?.toString();
  });

  useHead({
    htmlAttrs: {
      lang: config?.language,
    },
    meta: [
      {
        name: 'title',
        content: () => title.value,
      },
      {
        hid: 'google-site-verification',
        name: 'google-site-verification',
        content: () => googleSiteVerification?.value,
      },
      {
        hid: 'google-site-verification',
        name: 'google-site-verification',
        content: 'Oz86zsJFjHJXyIlViLuRexBczoNMBoCnAGUrkGiEiSY',
      },
      {
        hid: 'google-site-verification',
        name: 'google-site-verification',
        content: 'E21yT6tW5CeMFgNR7ha8qQfO8_cPrA8H7mocvcLtd6s',
      },
    ],
  });

  // Robots
  const robots = computed(() => {
    if (noIndex.value || runTimeConfig.public.environmentName !== 'production') {
      return 'noindex,nofollow';
    };
  });

  useSeoMeta({
    title: () => title.value,
    description: () => metaDescription?.value,
    ogSiteName: 'ZYN',
    ogTitle: () => openGraphTitle?.value,
    ogDescription: () => openGraphDescription?.value,
    ogImage: () => openGraphImage.value?.Url,
    ogType: 'website',
    appleMobileWebAppTitle: () => siteName.value,
    applicationName: () => siteName.value,
    msapplicationTileColor: '#da532c',
    robots: () => robots?.value,
  });

  useHead({
    link: [
      {
        rel: 'canonical',
        href: canonicalLink.value ? canonicalLink : host + route.path,
      },
      {
        rel: 'alternate',
        hreflang: config?.language,
        href: host + route.fullPath,
      },
      {
        rel: 'apple-touch-icon',
        type: 'image/png',
        sizes: '180x180',
        href: `/${faviconPath.value}/apple-touch-icon.png`,
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '96x96',
        href: `/${faviconPath.value}/favicon-96x96.png`,
      },
      {
        rel: 'manifest',
        href: `/${faviconPath.value}/site.webmanifest`,
      },
      {
        rel: 'shortcut icon',
        href: `/${faviconPath.value}/favicon.ico`,
      },
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: `/${faviconPath.value}/favicon-icon.png`,
      },
      ...hrefLangs,
    ].filter((l: any) => l),
    script: script,
  });

  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    'name': 'ZYN',
    url: host,
  };

  useJsonld(jsonLd);

  const jsonLdOrganization = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    '@id': host,
    'legalName': 'ZYN',
    'description': metaDescription?.value,
    'url': host + route.fullPath,
    'logo': host + '/icons/logo-niqo-co-black.svg',
    'sameAs': footerConfig.value?.socialLinks.map(element => {
      return element.linkUrl;
    }),
  } as any;

  if (pageData.value.UseOrganizationSchema) {
    useJsonld(jsonLdOrganization);
  }
}
</script>

<style scoped>
.fade-enter-active {
transition: opacity 0.15s;
}
.fade-leave-active {
transition: opacity 0.15s;
}
.fade-enter-from,
.fade-leave-to {
opacity: 0;
}

.loader {
  border-top-color: transparent;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
